import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout } from "./layouts/layout.js";
import { Preloader } from "./Utils/Preloader.js";

const HomeView = lazy(() => import("../Views/HomeView.js"));
const TreatmentsView = lazy(() => import("../Views/TreatmentsView.js"));
const TechnologiesView = lazy(() => import("../Views/TechnologiesView.js"));
const DiscountsView = lazy(() => import("../Views/DiscountsView.js"));
const ProfessionalsView = lazy(() => import("../Views/ProfessionalsView.js"));
const BlogView = lazy(() => import("../Views/BlogView.js"));
const FaqsView = lazy(() => import("../Views/FaqsView.js"));
const GrouponView = lazy(() => import("../Views/GrouponView.js"));
const LegalView = lazy(() => import("../Views/LegalView.js"));
const FeedbackView = lazy(() => import("../Views/FeedbackView.js"));
const PageNotFound = lazy(() => import("../Views/PageNotFound.js"));

export default function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>

                    <Route path="/" index element={
                        <Suspense fallback={<Preloader />}>
                            <HomeView />
                        </Suspense>} 
                    />

                    <Route path="/Tratamientos" element={
                        <Suspense fallback={<Preloader />}>
                            <TreatmentsView />
                        </Suspense>} 
                    />
                    <Route path="/Tratamientos/:treatmentPath" element={
                        <Suspense fallback={<Preloader />}>
                            <TreatmentsView />
                        </Suspense>} 
                    />
                    
                    <Route path="/Tecnologias" element={
                        <Suspense fallback={<Preloader />}>
                            <TechnologiesView />
                        </Suspense>} 
                    />
                    
                    <Route path="/Descuentos" element={
                        <Suspense fallback={<Preloader />}>
                            <DiscountsView />
                        </Suspense>} 
                    />
                    <Route path="/Descuentos/:descuentoUrl" element={
                        <Suspense fallback={<Preloader />}>
                            <DiscountsView />
                        </Suspense>} 
                    />
                    
                    <Route path="/Profesionales" element={
                        <Suspense fallback={<Preloader />}>
                            <ProfessionalsView />
                        </Suspense>} 
                    />
                    
                    <Route path="/Blog" element={
                        <Suspense fallback={<Preloader />}>
                            <BlogView />
                        </Suspense>} 
                    />
                    
                    <Route path="/preguntas-frecuentes" element={
                        <Suspense fallback={<Preloader />}>
                            <FaqsView />
                        </Suspense>} 
                    />
                    <Route path="/preguntas-frecuentes/:preguntaUrl" element={
                        <Suspense fallback={<Preloader />}>
                            <FaqsView />
                        </Suspense>} 
                    />
                    
                    <Route path="/groupon/:ticket" element={
                        <Suspense fallback={<Preloader />}>
                            <GrouponView />
                        </Suspense>} 
                    />
                    
                    <Route path="/terminos-legales" element={
                        <Suspense fallback={<Preloader />}>
                            <LegalView />
                        </Suspense>} 
                    />
                    <Route path="/politica-de-cookies" element={
                        <Suspense fallback={<Preloader />}>
                            <LegalView />
                        </Suspense>} 
                    />
                    
                    <Route path="/opinion" element={
                        <Suspense fallback={<Preloader />}>
                            <FeedbackView />
                        </Suspense>} 
                    />
                    
                    <Route path="*" element={
                        <Suspense fallback={<Preloader />}>
                            <PageNotFound />
                        </Suspense>} 
                    />

                </Route>
            </Routes>
        </BrowserRouter>
    )
}